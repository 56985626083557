import { getUpdateTaskInfo } from "@/api";
import { mdToast } from "../../utils/tools";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    task_id: {
      type: String,
      default: ''
    },
    pro_id: {
      type: String,
      default: ''
    }
  },
  computed: {
    ifShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    },
    tips_type_name() {
      var name = '';
      switch (Number(this.tips_type)) {
        case 1:
          name = '预热阶段';
          break;
        case 2:
          name = '众筹中';
          break;
        case 3:
          name = '众筹结束';
          break;
        case 4:
          name = '众筹结束未交付';
          break;
        case 5:
          name = '众筹发货后';
          break;
        default:
          break;
      }
      return name;
    }
  },
  mounted() {
    this.sendUpdateInfoRequest();
  },
  data() {
    return {
      tips_type: 0,
      //1 预热阶段 2 众筹中 3 众筹结束 4 众筹结束未交付期 5 众筹发货后
      pro_title: ''
    };
  },
  methods: {
    async sendUpdateInfoRequest() {
      const {
        status,
        data,
        message
      } = await getUpdateTaskInfo({
        pro_id: this.pro_id,
        task_id: this.task_id
      });
      if (status == 0) {
        this.tips_type = data.tips_type;
        this.pro_title = data.title;
      } else {
        mdToast(message);
      }
    },
    btnAction(event, index) {
      if (1 == index) {
        this.$emit('guideToPCUpdateAction', {
          'event': event,
          'pro_id': this.pro_id
        });
      } else {
        this.ifShow = false;
      }
    }
  }
};